import {timezoneOffsetCurrent} from "../app/variables";

export default {
 methods: {
        getTimezoneData(){
            let dateObj = new Date();

			let timezoneOffset = -(dateObj.getTimezoneOffset() / 60);
			dateObj.setTime(dateObj.getTime() + ((timezoneOffsetCurrent - timezoneOffset) * 60 * 60 * 1000))

              return dateObj;
        }
    }
}
