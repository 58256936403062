
import { mapActions, mapMutations } from "vuex";
import formValidation from "@/mixins/formValidation";
import filters from "@/mixins/filters";
export default {
  mixins: [formValidation, filters],

  data() {
    return {
      once: true,
      isActive: false,
      stateMessageCallBack: false,
      localModal: false
    };
  },
  methods: {
    onFocus() {
      this.setFocusShowFixes(false);
    },
    onFocusOut() {
      setTimeout(() => {
        this.setFocusShowFixes(true);
      }, 1000);
    },
    checkForm() {
      if (this.form.name.value.length < 2) {
        this.form.name.valid = false;
        return false;
      }
      if (!this.form.phone.valid) {
        this.form.phone.valid = false;
        return false;
      }
      if (!this.form.agree) {
        this.error = "agree";
        return false;
      }
      if (!this.form.agreeRf) {
        this.error = "agree";
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.checkForm()) {
        let formData = {
          chosen_car: {},
          type: "callback",
          client_name: this.form.name.value,
          client_phone: this.form.phone.value
        };
        if (localStorage.utm_source) {
          formData.utm_source = localStorage.utm_source;
        }
        if (localStorage.utm_medium) {
          formData.utm_medium = localStorage.utm_medium;
        }
        if (localStorage.utm_campaign) {
          formData.utm_campaign = localStorage.utm_campaign;
        }
        if (localStorage.utm_term) {
          formData.utm_term = localStorage.utm_term;
        }
        if (localStorage.utm_content) {
          formData.utm_content = localStorage.utm_content;
        }
        await this.sendForm(formData);
        await this.closeModal();
      }
    },
    windowScroll() {
      if (this.once) {
        this.once = false;
        setTimeout(() => {
          this.isActive = true;
        }, 4000);
      } else {
        return false;
      }
    },
    ...mapActions({
      openModal: "modal/modal-main/openModal",
      sendForm: "form/form/sendForm",
      closeModal: "modal/modal-main/closeModal"
    }),
    ...mapMutations({
      setFocusShowFixes: "car/car/SET_FOCUS_SHOW_FIXED"
    }),
    async callback() {
      let payload = {
        modal_component: "modal-callback-main"
      };
      await this.openModal(payload);
    }
  },
  mounted() {
    this.windowScroll();
  }
};
