import {mapGetters} from "vuex";
import timezoneData from "./timezoneData";

export default {
	mixins: [timezoneData],
  computed: {
    ...mapGetters({
			isNY: 'condition/isNY'
		}),
    isNight() {
      if(this.isNY){
        return true
      }
	  let dateObj = this.getTimezoneData()
	  let hours = dateObj.getHours()

      return (hours >= 21 || hours <= 8);
    }
  }
}
