import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=59091c8e&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterInf: require('/home/d/didisher2/si-auto.ru/www/components/Footer/footer-inf.vue').default,FooterLegal: require('/home/d/didisher2/si-auto.ru/www/components/Footer/footer-legal.vue').default,TextFooter: require('/home/d/didisher2/si-auto.ru/www/components/text/text-footer.vue').default})
