
import {mapGetters} from "vuex";
import isNight from "../../mixins/isNight";
export default {
  mixins: [isNight],
  computed: {
    ...mapGetters({
      settings: 'settings/settings/settings',
      menu: 'header/header/getMenu'
    }),
    isNight() {
      if (this.isNY) {
        return true
      }
      let dateObj = this.getTimezoneData()
      let hours = dateObj.getHours()

      return (hours >= 21 || hours <= 8);
    }
  },
  methods: {
    menuItem(link) {
      this.$router.push(link)
      this.isOpen = false;
    },
    open() {
      this.$store.commit('showModal', {currentForm: 'forms-callback', title: 'Заказать звонок'})
    },
    isChecked(index) {
      return this.activeItems.indexOf(index) !== -1
    },
    itemClick(index) {
      if (this.isChecked(index)) {
        this.activeItems = this.activeItems.filter(item => item !== index)
      } else {
        this.activeItems.push(index)
      }
    }
  },
  data() {
    return {
      isOpen: false,
      activeItems: []
    }
  },
}
