
import { mapActions, mapGetters, mapMutations } from "vuex";
import banks from "@/apollo/queries/bank/banks";
import seoTags from "@/mixins/seoTags";

export default {
  mixins: [seoTags],
  computed: {
    ...mapGetters({
      bank: 'banks/tinkoff',
      benefits_autoCredit: 'benefits/benefits_autoCredit',
      settings: 'settings/settings/settings',
    })
  },
  methods: {
    ...mapActions({
      request: 'filters/filters/request',
    }),
    ...mapMutations({
      setBank: 'banks/SET_BANK'
    })
  },
  async mounted() {
    setTimeout(function () { window.scrollTo(0, -100); }, 1);
    try {
      let response = await this.request({ query: banks, variables: { slug: this.$route.params.banks } })
      await this.setBank(response.data.banks)
    } catch (error) {
      return this.$nuxt.error({ statusCode: 404, message: '404' })
    }
  }
}
